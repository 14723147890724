import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../styling/Profile.css';

const Profile = ({isSidebarOpen}) => {
  const { user, isAuthenticated, isLoading } = useAuth0();


  return (
    isAuthenticated && (
      <div className={'homepage sidebar-closed'}>
        <h1>Profile</h1>
        <div className="profile-info">
          <img src={user.picture} alt="Profile" className="profile-picture" />
          <p><strong>Name:</strong> {user.name}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Nickname:</strong> {user.nickname}</p>
        </div>
      </div>
    )
  );
};

export default Profile;
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '../styling/NoorPage.css';
import 'primeicons/primeicons.css';

const NoorPage = () => {
  const { noorId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const socketRef = useRef(null);
  const title = sessionStorage.getItem('noorTitle');
  

  const wsBaseUrl = process.env.REACT_APP_WS_URL;
  const apiUrl = process.env.REACT_APP_API_URL
  const feUrl = process.env.REACT_APP_FRONTEND_URL

  useEffect(() => {
    const initializeWebSocket = async () => {
      try {
        const token = await getAccessTokenSilently();
        socketRef.current = new WebSocket(`${wsBaseUrl}/ws/noor/${noorId}?token=${token}`);

        socketRef.current.onopen = () => {
          console.log('WebSocket connected');
        };

        socketRef.current.onmessage = (event) => {
          console.log(`detecting message type: ${event.data}`)
          const message = { type: event.data.includes('user_message') ? 'user_message' : 'ai_message', content: event.data };
          setMessages((prevMessages) => [...prevMessages, message]);
        };

        socketRef.current.onclose = () => {
          console.log('WebSocket disconnected');
        };
      } catch (error) {
        console.error('Error getting token or initializing WebSocket:', error);
      }
    };

    initializeWebSocket();

    return () => {
      socketRef.current?.close();
    };
  }, [noorId, getAccessTokenSilently]);

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (input.trim() !== '') {
      const userMessage = { type: 'user_message', content: input };
      socketRef.current.send(input);
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInput('');
    }
  };

  const chatLogRef = useRef(null);

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="copilot-container">
      <header className="chat-title">
        <h1>{title}</h1>
      </header>

      {/* Chat Section */}
      <div className="chat-container">
        <div className="chat-log" ref={chatLogRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`chat-message ${message.type === 'user_message' ? 'user-message' : 'ai-message'
                }`}
            >
              <div className="message-wrapper">
                <span className="message-author">
                  {console.log(message.type)}
                  {message.type === 'user_message' ? 'You: ' : 'Mina: '}
                </span>
                <span className="message-content" style={{whiteSpace: 'pre-wrap'}}>
                  {console.log(message.content)}{message.content}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Input Section */}
      <form className="chat-input-form" onSubmit={handleSendMessage}>
        <div className="input-wrapper">
          <input
            type="text"
            className="chat-input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            aria-label="Chat input"
          />
          <button type="submit" className="send-button" aria-label="Send message">
            <i className="pi pi-chevron-right" style={{ fontSize: '1rem' }}></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default NoorPage;
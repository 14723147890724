import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Starburst from './Starburst';
import AssistPage from './AssistPage';
import '../styling/HomePage.css';
import Autocomplete from '@mui/joy/Autocomplete';
import Carousel from './Carousel';
import Searchbar from './Searchbar';

import { Button } from 'primereact/button';
import { Accordion} from 'rsuite';
import { FaAngleDoubleDown } from 'react-icons/fa';


const fetchData = async (url, setData) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
      setData(data);
    } else {
      console.error("API response is not an array:", data);
      setData([]); // Fallback to an empty array if data is not an array
    }
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    setData([]); // Fallback to an empty array on error
  }
};

const HomePage = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  // const [isChatOpen, setIsChatOpen] = useState(false);
  const [noors, setNoors] = useState([]);
  const [recentNoors, setRecentNoors] = useState([]);
  const [personalNoors, setPersonalNoors] = useState([]);
  const scrollRef = useRef(null);
  const [visible, setVisible] = useState(false)
  const userSub = user?.sub;
  const [open, setOpen] = React.useState(false);

  const wsBaseUrl = process.env.REACT_APP_WS_URL;
  const apiUrl = process.env.REACT_APP_API_URL
  const feUrl = process.env.REACT_APP_FRONTEND_URL

  useEffect(() => {
    fetchData(`${apiUrl}/top-noors`, setNoors);
  }, []);

  // Fetch data for "recent-noor-details" on component mount
  useEffect(() => {
    fetchData(`${apiUrl}/recent-noor-details`, setRecentNoors);
  }, []);


  useEffect(() => {
    if (userSub) {  // Only run fetch if userSub exists
      fetchData(`${apiUrl}/user-noors/${userSub}`, setPersonalNoors);
    }
  }, [userSub]);

  const handleAssistClick = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);


  return (
    <div className={'homepage sidebar-closed'}>

      <div class="search-bar">
        <Searchbar recentNoors={recentNoors} />
      </div>

      <div class="login-button">
      {!isAuthenticated ? <Button label="Login" icon="pi pi-sign-in" iconPos="right" raised onClick={() => loginWithRedirect()} style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }} /> :<div></div> }
      </div>

      <div className="starburst-container">
        <Starburst onClick={handleAssistClick} />
      </div>

    

      <AssistPage open={open} onClose={handleClose} />

      <div class="carousels">
        <div>
          <h2>Most Popular Noors</h2>

          <Carousel noors={noors.map((noor) => ({
            id: noor.noor_id,
            title: noor.title,
            category: noor.category,
            interactions: noor.interactions,
            created_at: noor.created_at
          }))}
            instanceId="popularNoorsCarousel" />
        </div>
        <div>
          <h2>Most Recent Noors</h2>

          <Carousel noors={recentNoors.map((noor) => ({
            id: noor.noor_id,
            title: noor.title,
            category: noor.category,
            interactions: noor.interactions,
            created_at: noor.created_at
          }))}
            instanceId="recentNoorsCarousel" />
        </div>

        {isAuthenticated ? (
          <section className="noors-section">
            {/* <div class="personal-noors"> */}
            <Accordion defaultActiveKey={1} >
              <Accordion.Panel header="Your Noors" eventKey={1} caretAs={FaAngleDoubleDown} defaultExpanded={true} bodyFill>
                <Carousel noors={personalNoors.map((noor) => ({
                  id: noor.noor_id,
                  title: noor.title,
                  category: noor.category,
                  interactions: noor.interactions,
                  created_at: noor.created_at
                }))}
                  instanceId="PersonalNoorCarousel" />
              </Accordion.Panel>
            </Accordion>
            {/* </div> */}
          </section>


        ) : (
          <div className="join-prompt">
            <p>Join MinaAI to create your own Noors and interact with Mina AI!</p>
            <button className="join-button" onClick={() => loginWithRedirect()}>
              Join MinaAI
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
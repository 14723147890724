
import React, { useState, useEffect } from 'react';import '../styling/Starburst.css';

const Starburst = ({ onClick }) => {
  const [showOriginal, setShowOriginal] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);
 
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > 50 && currentScrollPos > lastScrollPos) {
        // Scrolling down past the threshold: fade out original, fade in bottom-right
        setShowOriginal(false);
      } else if (currentScrollPos <= 0 && currentScrollPos < lastScrollPos) {
        // Scrolling up to the threshold: fade out bottom-right, fade in original
        setShowOriginal(true);
      }

      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollPos]);


  return (
    <>
      {/* Original Position */}
      <div
        className={`starburst-original ${showOriginal ? 'isvisible' : 'ishidden'}`}
        onClick={onClick}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      ></div>

      {/* Bottom-Right Position */}
      <div
        className={`starburst-bottom-right ${showOriginal ? 'ishidden' : 'isvisible'}`}
        onClick={onClick}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      ></div>
    </>
  );
};

export default Starburst;

import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import { useAuth0 } from '@auth0/auth0-react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Ripple } from 'primereact/ripple';
import { StyleClass } from 'primereact/styleclass';


export default function FullSidebar() {
    const [visible, setVisible] = useState(false);
    const { user, isAuthenticated, logout } = useAuth0();
    const btnRef1 = useRef(null);
    const btnRef2 = useRef(null);
    const btnRef3 = useRef(null);
    const btnRef4 = useRef(null);
    const getLogoutUrl = () => process.env.REACT_APP_LOGOUT_URL || window.location.origin;

    const value = {
        ripple: true,

    };

    const navigate = useNavigate(); // Initialize the navigation hook

    const handleNavigation = (e) => {
        e.preventDefault(); // Prevent default behavior if necessary
        navigate('/'); // Navigate to the root (or any other route you want)
    };

    return (

        <div className="card flex justify-content-center">
            <div className='sidebarbutton' style={{
                position: 'fixed',
                top: '10px',
                left: '10px',
                padding: '10px',
                borderRadius: '5px',
                zIndex: 100
            }}>
                <Button icon="pi pi-bars" className="p-button-rounded" onClick={() => setVisible(true)} /></div>


            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                content={({ closeIconRef, hide }) => (
                    <div className="min-h-screen flex relative lg:static surface-ground">
                        <div id="app-sidebar-2" className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none" style={{ width: '280px' }}>
                            <div className="flex flex-column h-full">
                                <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                                    <span className="inline-flex align-items-center gap-2">

                                        <span className="font-semibold text-2xl text-primary">MINA AGI</span>
                                    </span>
                                    <span>
                                        <Button type="button" ref={closeIconRef} onClick={(e) => hide(e)} icon="pi pi-times" rounded outlined className="h-2rem w-2rem"></Button>
                                    </span>
                                </div>
                                <div className="overflow-y-auto">
                                    <ul className="list-none p-3 m-0">
                                        <li>
                                            <ul className="list-none p-0 m-0 overflow-hidden">
                                                <li>
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={handleNavigation}>
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium">Home</span>
                                                        <Ripple />
                                                    </a>
                                                </li>
                                                <li>
                                                    <Link to="/categories" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                            <i className="pi pi-bookmark mr-2"></i>
                                                            <span className="font-medium">Categories</span>
                                                            <Ripple />
                                                        </a>
                                                    </Link>

                                                </li>

                                                <li>
                                                    <Link to="/settings" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                            <i className="pi pi-cog mr-2"></i>
                                                            <span className="font-medium">Settings</span>
                                                            <Ripple />
                                                        </a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={() =>
                                                        logout({
                                                            logoutParams: {
                                                                returnTo: getLogoutUrl()
                                                            }
                                                        })}>
                                                        <i className="pi pi-sign-out mr-2"></i>
                                                        <span className="font-medium">Logout</span>
                                                        <Ripple />
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-auto">
                                    <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                                    <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>

                                        <a v-ripple className="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <Avatar image={user.picture} shape="circle" />
                                            <span className="font-bold">{user.given_name} {user.family_name}</span>
                                        </a>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
            ></Sidebar>

        </div>
    )

}

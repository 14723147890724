import React, { useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useNavigate } from "react-router-dom"; 

export default function Searchbar({recentNoors}) {
    const [value, setValue] = useState('');
    const [filteredNoors, setFilteredNoors] = useState([]);
    const navigate = useNavigate();


    const processedNoors = recentNoors.map((noor) => ({
        id: noor.noor_id,
        title: noor.title,
        category: noor.category,
        interactions: noor.interactions,
        created_at: noor.created_at,
    }));
    const search = (event) => {
        const query = event.query.toLowerCase();
        const results = processedNoors.filter(noor => noor.title.toLowerCase().includes(query));
        setFilteredNoors(results);
    };

    const handleSelect = (event) => {
      if (event.value) {
          navigate(`/noor/${event.value.id}`);
      }
  };

    return (
        <div className="card flex justify-content-center">
            <span className="p-float-label">
            <AutoComplete
                    inputId="ac"
                    value={value}
                    suggestions={filteredNoors}
                    completeMethod={search}
                    field="title"
                    onChange={(e) => setValue(e.value)} 
                    onSelect={handleSelect} 
                />
                <label htmlFor="ac">Search Noors</label>
            </span>
        </div>
    )
}
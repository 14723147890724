import React from 'react';
import '../styling/Settings.css';

const Settings = ({isSidebarOpen}) => {
  return (
    <div className={'settings-page sidebar-closed'}>
      <h1>Settings</h1>
      <div className="settings-options">
        <div className="settings-option">
          <label>Enable Email Notifications:</label>
          <input type="checkbox" />
        </div>
        <div className="settings-option">
          <label>Show Profile Publicly:</label>
          <input type="checkbox" />
        </div>
        {/* Add more settings options as needed */}
      </div>
    </div>
  );
};

export default Settings;
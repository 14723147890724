import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HomePage from './components/HomePage';  // Import HomePage component
import NoorPage from './components/NoorPage';  // Import NoorPage component
import Profile from './components/Profile';  // Import Profile page component
import Settings from './components/Settings';  // Import Settings page component
import AssistPage from './components/AssistPage';
import FullSidebar from './components/SidebarComponent';

import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import 'rsuite/Accordion/styles/index.css';
import 'rsuite/Animation/styles/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Protected Route component to restrict access to authenticated users
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? children : <Navigate to="/" />;
};

const App = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();  // For handling loading and error states
  const [isSidebarOpen, setSidebarOpen] = useState(true);  // State to control sidebar visibility
  const isNoorPage = window.location.pathname.startsWith('/noor');

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };


  return (
    <PrimeReactProvider>
      <Router>
        {!isNoorPage && isAuthenticated && <FullSidebar />}
        <div className={`app-container ${isNoorPage ? 'sidebar-closed' : ''}`}>
          {/* Main content area, dynamically rendered by Routes */}
          <div className="main-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/noor/:noorId" element={<ProtectedRoute><NoorPage /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute> <Profile /> </ProtectedRoute>} />
              <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              <Route path="/assist" element={<ProtectedRoute><AssistPage /></ProtectedRoute>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </Router>
    </PrimeReactProvider>
  );
};

export default App;
import { Link } from 'react-router-dom';
// import '../styling/NoorCard.css';
import { useState, useEffect } from "react";
import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';


import { Modal, Button } from 'rsuite';
import 'rsuite/Modal/styles/index.css';
import 'rsuite/Button/styles/index.css';


function useTimeUpdater(createdAt) {
  const [timeValue, setTimeValue] = useState("");

  const calculateTimeDifference = () => {
    const createdDate = new Date(createdAt);
    const now = new Date();
    const diffInSeconds = (now - createdDate) / 1000;

    if (diffInSeconds < 180) return "a moment ago"; // Less than 3 minutes
    if (diffInSeconds < 300) return "5 minutes ago"; // Less than 5 minutes
    if (diffInSeconds < 600) return "around 10 minutes ago"; // Between 5-10 mins
    if (diffInSeconds < 7200) return "1 hour ago"; // Less than 2 hours

    // More than 2 hours: return formatted date
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return createdDate.toLocaleString("en-US", options);
  };

  useEffect(() => {

    setTimeValue(calculateTimeDifference());

    const interval = setInterval(() => {
      setTimeValue(calculateTimeDifference());
    }, 60000);

    return () => clearInterval(interval);
  }, [createdAt]);

  return timeValue;
}




export default function NoorCard({ noor, section }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const handleOpenModal = (url) => {
    setModalUrl(url);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    sessionStorage.removeItem('noorTitle');
    setIsModalOpen(false);
    setModalUrl('')
  };

  const created_at = useTimeUpdater(noor.created_at)
  let imagePath;

  try {
    imagePath = require(`../assets/images/${noor.category.toLowerCase()}.png`);
  } catch (error) {
    console.error("Image not found:", error);
    imagePath = require("../assets/images/starburst.png"); // Fallback image
  }

  return (
    <Card variant="outlined" sx={{ width: 350 }} size='sm'>
      <CardOverflow>
        <AspectRatio ratio="2">
          <img
            src={imagePath}
            srcSet={imagePath}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography level="title-sm">
          <Link component="button"
            onClick={() => {
              sessionStorage.setItem('noorTitle', noor.title);
              handleOpenModal(`/noor/${noor.id}`);
            }}
            overlay
            underline="none">
            {noor.title}
          </Link>
        </Typography>
        <Typography level="body-sm">
          <Link component="button"
            onClick={() => {
              sessionStorage.setItem('noorTitle', noor.title);
              handleOpenModal(`/noor/${noor.id}`);
            }} overlay underline="none">
            {noor.category}
          </Link>
        </Typography>
      </CardContent>
      <CardOverflow variant="soft">
        <Divider inset="context" />
        <CardContent orientation="horizontal">
          <Typography level="body-xs">{noor.interactions} {noor.interactions > 1 ? "Collaborations" : "Collaboration"}</Typography>
          <Divider orientation="vertical" />
          <Typography level="body-xs">{section === "recentNoorsCarousel" ? "Updated" : "Created"}: {created_at}</Typography>
        </CardContent>
      </CardOverflow>

      <Modal open={isModalOpen} keyboard={true} onClose={handleCloseModal} size="sm">
        <style>
          {`
      .rs-modal-content {
        padding: 0 !important; /* Remove padding */
      }
    `}
        </style>
        <Modal.Body style={{ height: '800px', paddingBottom: '0px' }}>
          {isModalOpen && modalUrl ? (
            <iframe
              src={modalUrl}
              title="Modal Content"
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                padding: 0
              }}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
      </Modal>
    </Card>

  );
}



import React from 'react';
import '../styling/Carousel.css';
import NoorCard from './NoorCard';

const Carousel = ({ noors, instanceId }) => {
  const scrollToSection = (wrapperId,sectionId) => {
    const wrapper = document.getElementById(wrapperId);
    // const section = document.getElementById(sectionId);
    const section = wrapper?.querySelector(`#${sectionId}`); 
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };

  return (
    <div>
      <div id={instanceId} className="wrapper">
        <section id={`section1-${instanceId}`}>
          <button onClick={() => scrollToSection(instanceId,`section3-${instanceId}`)} className="arrow__btn">‹</button>
          {noors.slice(0, 4).map((noor) => (
            <div key={noor.id} className="item">
              <NoorCard noor={noor} section={instanceId} />
            </div>
          ))}
          <button onClick={() => scrollToSection(instanceId,`section2-${instanceId}`)} className="arrow__btn">›</button>
        </section>
        
        <section id={`section2-${instanceId}`}>
          <button onClick={() => scrollToSection(instanceId,`section1-${instanceId}`)} className="arrow__btn">‹</button>
          {noors.slice(4, 8).map((noor) => (
            <div key={noor.id} className="item">
              <NoorCard noor={noor} section={instanceId} />
            </div>
          ))}
          <button onClick={() => scrollToSection(instanceId,`section3-${instanceId}`)} className="arrow__btn">›</button>
        </section>

        <section id={`section3-${instanceId}`}>
          <button onClick={() => scrollToSection(instanceId,`section2-${instanceId}`)} className="arrow__btn">‹</button>
          {noors.slice(8, 12).map((noor) => (
            <div key={noor.id} className="item">
              <NoorCard noor={noor} section={instanceId} />
            </div>
          ))}
          <button onClick={() => scrollToSection(instanceId,`section1-${instanceId}`)} className="arrow__btn">›</button>
        </section>
        
      </div>
    </div>
  );
};

export default Carousel;